<template>
    <div class="input-container-wrapper">
        <div class="input-container">
            <label
                :for="inputId"
                class="form-label label"
            >
                {{ inputLabel }}
                <span v-if="isRequired" class="attribute-required">*</span>
            </label>
            <input
                v-if="inputMode === 'input'"
                :id="inputId"
                class="form-control input"
                :type="inputType"
                :name="inputName"
                v-model.trim="valueCopy"
                :placeholder="inputPlaceholder"
                :disabled="isDisabled"
                @blur="startPartialValidation"
                @keydown.enter.prevent=""
            />
            <textarea
                v-else
                :id="inputId"
                class="form-control textarea"
                v-model.trim="valueCopy"
                :placeholder="inputPlaceholder"
                :disabled="isDisabled"
                @blur="startPartialValidation"
            ></textarea>
            <div class="error-text">{{ errorText }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TheInput",
    props: {
        /**
         * Input mode - input | textarea
         */
        inputMode: {
            type: String,
            default: 'input'
        },
        inputId: {
            type: String,
            default: ''
        },
        /**
         * Input mode - text | number
         */
        inputType: {
            type: String,
            default: 'text'
        },
        inputName: {
            type: String,
            default: ''
        },
        inputLabel: {
            type: String,
            default: ''
        },
        inputPlaceholder: {
            type: String,
            default: ''
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        value: [String, Number],
        errorText: {
            type: String,
            default: ''
        },
    },
    methods: {
        startPartialValidation() {
            this.$emit('start-validate')
        },
    },
    computed: {
        valueCopy: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('update:value', val)
            }
        }
    }
};
</script>

<style lang="scss" scoped>
//.input-container-wrapper::v-deep(.input-container) {
//    //
//}
</style>