<template>
    <metainfo>
        <template
            v-slot:title="{ content }"
        >
            {{ content ? `${content} | Zealy.sk` : `Zealy.sk` }}
        </template>
    </metainfo>

    <TheMenu />
    <router-view />
    <TheFooter />
    <TheToast ref="ref_toast" />

<!--    <div class="component-info-banner" v-if="!getCookiesConsent">-->
<!--        <div class="info-banner container">-->
<!--            <div class="info-banner-text">-->
<!--                <p class="title">{{ $t('cookiesLaw.title') }}</p>-->
<!--                <p class="description">{{ $t('cookiesLaw.description') }}</p>-->
<!--            </div>-->
<!--            <div class="understand-button" @click="handleCookiesConsent">-->
<!--                {{ $t('understand') }}-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import TheMenu from "./components/general/TheMenu.vue";
import TheFooter from "./components/general/TheFooter.vue";
import positionService from "./functions/services/positionService";
import TheToast from "./components/elements/TheToast.vue";

export default {
    name: 'App',
    mixins: [ positionService ],
    components: {
        TheToast,
        TheFooter,
        TheMenu
    },
    metaInfo() {
        return {
            title: 'Kariérny navigátor',
            titleTemplate: '%s | Zealy.sk',
            htmlAttrs: {
                lang: 'sk',
                amp: true
            },
            meta: [
                { charset: 'utf-8' },
                { name: 'description', Name: 'description', content: 'Zealy je informačný portál, ktorý formou štruktúrovaných video profilov podrobne a autenticky opisuje pracovné pozície.' },
                { name: 'author', Name: 'author', content: 'wame.sk' },
                { name: 'robots', Name: 'robots', content: 'noindex, nofollow' },
            ],
        }
    },
    created() {
        // register bootstrap (for modals, toasts,...)
        window.bootstrap = require("bootstrap")

        this.defineDeviceType()
    },
    mounted() {
        this.getWebSettings()
        this.getPositionParameters()
    },
    watch: {
        'getForceLogout': {
            handler(val) {
                if (val) {
                    this.logoutUser(false)
                    this.setForceLogout(false)
                }
            },
            immediate: true
        },
    },
    methods: {
        ...mapActions({
            clearAllStores: 'clearAllStores',
            storeWebSettings: 'web/storeWebSettings',
            storePositionParameters: 'content/storePositionParameters',
        }),
        ...mapMutations({
            setDeviceType: 'web/setDeviceType',
            setForceLogout: 'web/setForceLogout',
            setCookiesConsent: 'web/setCookiesConsent',
        }),
        async defineDeviceType() {
            const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
            const isMobile =  regex.test(window.navigator.userAgent);

            if (isMobile) {
                const userAgent = window.navigator.userAgent
                if (/iPhone|iPad|iPod/i.test(userAgent)) {
                    this.setDeviceType("ios")
                } else if (/Android/i.test(userAgent)) {
                    this.setDeviceType("android")
                } else {
                    this.setDeviceType("other")
                }
            } else {
                this.setDeviceType("web")
            }
        },
        getWebSettings() {
            this.$axios.get(this.$config.API_URL_V1 + 'settings').then(res => {
                // console.log('getWebSettings', res);
                this.storeWebSettings(res.data.data)
            })
        },
        getPositionParameters() {
            this.axiosGetPositionParameters().then(res => {
                // console.log('getPositionParameters', res);
                this.storePositionParameters(res.data.data)
            })
        },
        logoutUser(postLogout = true) {
            // console.log('start logoutUser');

            if (postLogout) {
                this.$axios.post(this.$config.API_URL_V1 + 'logout', {}, this.$config.HEADERS).then(res => {
                    // console.log('logoutUser', res);
                    this.$root.$refs.ref_toast.showToast(res.data?.message, 'toast-success')
                }).finally(() => {
                    this.clearAllStores()
                    this.redirectToPage('PageHome')
                })
            } else {
                this.clearAllStores()
                this.redirectToPage('PageHome')
            }
        },
        handleCookiesConsent() {
            this.setCookiesConsent(true)
        }
    },
    computed: {
        ...mapGetters({
            'getForceLogout': 'web/getForceLogout',
            'getCookiesConsent': 'web/getCookiesConsent',
        })
    }
}
</script>

<style lang="scss">
.component-info-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: $secondary;
    padding: 20px 0;
    z-index: 10;

    .info-banner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        height: 100%;
        color: $white;

        .info-banner-text {
            .title {
                font-weight: 600;
            }

            .description {
                font-size: 14px;
            }
        }
    }
    
    .understand-button {
        cursor: pointer;
        font-weight: 600;
    }

    @media (max-width: 767px) {
        .info-banner {
            flex-direction: column;
        }
    }
}

</style>
