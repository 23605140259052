<template>
    <div class="component-profile-registration-form">
        <div class="profile-registration-form">

            <form class="form-container" id="profileRegistrationForm" autocomplete="on" @keydown.enter="confirmForm">

                <div class="row row-items">
                    <div class="col-item col-12 col-lg-6">
                        <TheInput
                            input-id="inputName"
                            input-name="name"
                            v-model:value="formData.name"
                            :is-required="true"
                            :input-label="$t('name.label')"
                            :input-placeholder="$t('name.placeholder')"
                            :error-text="showInputError('name')"
                            @start-validate="startPartialValidation('name')"
                        />

                        <TheInput
                            input-id="inputSurname"
                            input-name="surname"
                            v-model:value="formData.surname"
                            :is-required="true"
                            :input-label="$t('surname.label')"
                            :input-placeholder="$t('surname.placeholder')"
                            :error-text="showInputError('surname')"
                            @start-validate="startPartialValidation('surname')"
                        />

                        <TheInput
                            input-id="inputEmail"
                            input-name="email"
                            input-type="email"
                            v-model:value="formData.email"
                            :is-required="true"
                            :is-disabled="formType === 'profile'"
                            :input-label="$t('email.label')"
                            :input-placeholder="$t('email.placeholder')"
                            :error-text="showInputError('email')"
                            @start-validate="startPartialValidation('email')"
                        />

                        <TheInput
                            input-id="inputPassword"
                            input-name="password"
                            input-type="password"
                            v-model:value="formData.password"
                            :is-required="true"
                            :input-label="$t('password.label')"
                            :input-placeholder="$t('password.placeholder')"
                            :error-text="showInputError('password')"
                            @start-validate="startPartialValidation('password')"
                        />

                        <TheInput
                            input-id="inputPasswordConfirmation"
                            input-name="password-confirmation"
                            input-type="password"
                            v-model:value="formData.passwordConfirmation"
                            :is-required="true"
                            :input-label="$t('passwordConfirmation.label')"
                            :input-placeholder="$t('passwordConfirmation.placeholder')"
                            :error-text="showInputError('passwordConfirmation')"
                            @start-validate="startPartialValidation('passwordConfirmation')"
                        />
                    </div>

                    <div class="col-item col-12 col-lg-6">
                        <div class="input-container">
                            <InputPhoneNumber
                                v-model:phone-number.trim="formData.phoneNumber"
                                ref="ref_phone_input"
                            />
                        </div>

                        <div class="inputs-row">
                            <TheInput
                                input-id="inputStreet"
                                input-name="street"
                                v-model:value="formData.street"
                                :is-required="true"
                                :input-label="$t('street.label')"
                                :input-placeholder="$t('street.placeholder')"
                                :error-text="showInputError('street')"
                                @start-validate="startPartialValidation('street')"
                            />

                            <TheInput
                                input-id="inputStreetNumber"
                                input-name="street-number"
                                v-model:value="formData.streetNumber"
                                :is-required="true"
                                :input-label="$t('streetNumber.label')"
                                :input-placeholder="$t('streetNumber.placeholder')"
                                :error-text="showInputError('streetNumber')"
                                @start-validate="startPartialValidation('streetNumber')"
                            />
                        </div>

                        <div class="inputs-row">
                            <TheInput
                                input-id="inputCity"
                                input-name="city"
                                v-model:value="formData.city"
                                :is-required="true"
                                :input-label="$t('city.label')"
                                :input-placeholder="$t('city.placeholder')"
                                :error-text="showInputError('city')"
                                @start-validate="startPartialValidation('city')"
                            />

                            <TheInput
                                input-id="inputPostalCode"
                                input-name="postal-code"
                                v-model:value="formData.postalCode"
                                :is-required="true"
                                :input-label="$t('postalCode.label')"
                                :input-placeholder="$t('postalCode.placeholder')"
                                :error-text="showInputError('postalCode')"
                                @start-validate="startPartialValidation('postalCode')"
                            />
                        </div>

                        <TheInput
                            input-id="inputCountry"
                            input-name="country"
                            v-model:value="formData.country"
                            :is-required="true"
                            :input-label="$t('country.label')"
                            :input-placeholder="$t('country.placeholder')"
                            :error-text="showInputError('country')"
                            @start-validate="startPartialValidation('country')"
                        />

                        <TheInput
                            v-if="formType === 'profile'"
                            input-id="inputIban"
                            input-name="iban"
                            v-model:value="formData.iban"
                            :is-required="false"
                            :input-label="$t('iban.label')"
                            :input-placeholder="$t('iban.placeholder')"
                            :error-text="showInputError('iban')"
                            @start-validate="startPartialValidation('iban')"
                        />

                        <div class="consents-wrapper" v-if="formType === 'registration'">
                            <div class="input-container form-check">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="checkAgreeWithGdpr"
                                    name="gdpr"
                                    v-model="formData.acceptGdpr"
                                >
                                <label class="form-check-label" for="checkAgreeWithGdpr">
                                    {{ $t('registrationConsents.agreeWith') }}
                                    <a class="link" :href="getWebSettingsByKey('vop')" target="_blank">
                                        {{ $t('registrationConsents.gdpr') }}
                                    </a>
                                    &nbsp;<span class="attribute-required">*</span>
                                </label>
                                <div class="error-text">{{ showInputError('acceptGdpr') }}</div>
                            </div>

                            <div class="input-container form-check">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="checkAgreeWithVop"
                                    name="vop"
                                    v-model="formData.acceptPrivacyPolicy"
                                >
                                <label class="form-check-label" for="checkAgreeWithVop">
                                    {{ $t('registrationConsents.agreeWith') }}
                                    <a class="link" :href="getWebSettingsByKey('gdpr')" target="_blank">
                                        {{ $t('registrationConsents.vop') }}
                                    </a>
                                    &nbsp;<span class="attribute-required">*</span>
                                </label>
                                <div class="error-text">{{ showInputError('acceptPrivacyPolicy') }}</div>
                            </div>
                        </div>
                    </div>
                </div>

            </form>

        </div>
    </div>
</template>

<script>
import TheInput from "../elements/TheInput.vue";
import validation from "../../functions/validation";
import { useVuelidate } from "@vuelidate/core/dist";
import InputPhoneNumber from "../elements/InputPhoneNumber.vue";
import { required, requiredIf, email, minLength, maxLength, numeric, sameAs } from '@vuelidate/validators'
import { mapGetters } from "vuex";

export default {
    name: "ProfileRegistrationForm",
    mixins: [ validation ],
    components: {
        InputPhoneNumber,
        TheInput
    },
    props: {
        formType: {
            type: String,
            default: 'registration' // registration | profile
        },
        errors: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            formData: {
                name: '',
                surname: '',
                email: '',
                password: '',
                passwordConfirmation: '',

                phoneNumber: '',
                street: '',
                streetNumber: '',
                city: '',
                postalCode: '',
                country: '',
                iban: '',

                acceptGdpr: false,
                acceptPrivacyPolicy: false,
            },
        };
    },
    methods: {
        prefillUserData(data) {
            this.formData.name = data.first_name || ''
            this.formData.surname = data.last_name || ''
            this.formData.email = data.email || ''

            this.formData.phoneNumber = data.phone || ''
            this.formData.street = data.street || ''
            this.formData.streetNumber = data.street_number || ''
            this.formData.city = data.city || ''
            this.formData.postalCode = data.zip_code || ''
            this.formData.country = data.country || ''
            this.formData.iban = data.iban || ''
        },
        /**
         * After leaving the input, start the validation of the given value
         * @param {string} key - data key
         * @example 'actualPositionName'
         */
        startPartialValidation(key) {
            const path = 'formData.' + key
            path.split('.').reduce((a, b) => this.v$[a][b].$touch())
        },
        confirmForm() {
            this.$emit("confirm-form")
        },
        submitData() {
            return new Promise((resolve, reject) => {
                const isFormCorrect = this.v$.$validate()
                const isPhoneNumCorrect = this.$refs.ref_phone_input.v$.$touch()
                if (!isFormCorrect || isPhoneNumCorrect === false) {
                    reject()
                    return
                }

                // prepare post data
                let postData = {}
                postData['first_name'] = this.formData.name
                postData['last_name'] = this.formData.surname
                postData['email'] = this.formData.email

                // only for registration form
                if (
                    this.formType === 'registration'
                    || (this.formData.password?.length || this.formData.passwordConfirmation?.length))
                {
                    postData['password'] = this.formData.password
                    postData['password_confirmation'] = this.formData.passwordConfirmation
                }

                postData['phone'] = this.formData.phoneNumber
                postData['street'] = this.formData.street
                postData['street_number'] = this.formData.streetNumber
                postData['city'] = this.formData.city
                postData['zip_code'] = this.formData.postalCode
                postData['country'] = this.formData.country

                if (this.formType === 'profile') {
                    postData['iban'] = this.formData.iban
                }

                if (this.formType === 'registration') {
                    postData['gdpr'] = this.formData.acceptGdpr
                    postData['vop'] = this.formData.acceptPrivacyPolicy
                }

                resolve(postData)
            })
        },
        resetFormData() {
            this.v$.$reset()

            this.formData['name'] = ''
            this.formData['surname'] = ''
            this.formData['email'] = ''
            this.formData['password'] = ''
            this.formData['passwordConfirmation'] = ''

            this.formData['phoneNumber'] = ''
            this.formData['street'] = ''
            this.formData['streetNumber'] = ''
            this.formData['city'] = ''
            this.formData['postalCode'] = ''
            this.formData['country'] = ''
            this.formData['iban'] = ''

            this.formData['acceptGdpr'] = false
            this.formData['acceptPrivacyPolicy'] = false
        }
    },
    computed: {
        ...mapGetters({
            getWebSettingsByKey: 'web/getWebSettingsByKey'
        }),
        showInputError() {
            return key => {
                const keyValuePath = 'formData.' + key
                return this.validationErrorHandler(this.v$, keyValuePath) || this.parseErrors[this.validationKey(keyValuePath)]?.[0]
            }
        },
        parseErrors() {
            let errors = {}
            if ('first_name' in this.errors) errors['name'] = this.errors?.first_name
            if ('last_name' in this.errors) errors['surname'] = this.errors?.last_name
            if ('email' in this.errors) errors['email'] = this.errors?.email
            if ('password' in this.errors) errors['password'] = this.errors?.password
            if ('password_confirmation' in this.errors) errors['passwordConfirmation'] = this.errors?.password_confirmation

            if ('phone' in this.errors) errors['phoneNumber'] = this.errors?.phone
            if ('street' in this.errors) errors['street'] = this.errors?.street
            if ('street_number' in this.errors) errors['streetNumber'] = this.errors?.street_number
            if ('city' in this.errors) errors['city'] = this.errors?.city
            if ('zip_code' in this.errors) errors['postalCode'] = this.errors?.zip_code
            if ('country' in this.errors) errors['country'] = this.errors?.country
            if ('iban' in this.errors) errors['iban'] = this.errors?.iban

            if ('gdpr' in this.errors) errors['acceptGdpr'] = this.errors?.gdpr
            if ('vop' in this.errors) errors['acceptPrivacyPolicy'] = this.errors?.vop
            return errors
        },
    },
    validations() {
        return {
            formData: {
                name: {
                    required
                },
                surname: {
                    required
                },
                email: {
                    required,
                    email
                },
                password: {
                    required: requiredIf(() => this.formType === 'registration' || this.formData.passwordConfirmation?.length),
                    minLength: minLength(8),
                    strongPassword(password) {
                        if (this.formType === 'registration' || this.formData.password?.length) {
                            return (
                                /[a-z]/.test(password) && // checks for a-z
                                /[A-Z]/.test(password) && // checks for A-Z
                                /[0-9]/.test(password) && // checks for 0-9
                                /\W|_/.test(password) && // checks for special char
                                password.length >= 8
                            );
                        } else {
                            return true
                        }
                    }
                },
                passwordConfirmation: {
                    required: requiredIf(() => this.formType === 'registration' || this.formData.password?.length),
                    sameAsPassword: sameAs(this.formData.password)
                },
                street: {
                    required,
                },
                streetNumber: {
                    required,
                },
                city: {
                    required,
                },
                postalCode: {
                    required,
                    minLength: minLength(5),
                    maxLength: maxLength(5),
                    numeric,
                },
                country: {
                    required,
                },
                iban: {
                    //
                },
                acceptGdpr: {
                    sameAs(val) {
                        return this.formType === 'registration' ? val : true
                    }
                },
                acceptPrivacyPolicy: {
                    sameAs(val) {
                        return this.formType === 'registration' ? val : true
                    }
                },
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.component-profile-registration-form::v-deep(.profile-registration-form) {
    .row-items {
        .col-item {
            .inputs-row {
                display: flex;
                gap: 20px;

                > :first-child {
                    width: 70%;
                }

                > :last-child {
                    width: 30%
                }
            }
        }
    }

    .consents-wrapper {
        margin-top: 33px;

        .input-container {
            margin-bottom: 0;
        }
    }

    @media (max-width: 992px) {
        .consents-wrapper {
            //margin-top: 15px;
        }
    }

    @media (max-width: 500px) {
        .row-items {
            .col-item {
                .inputs-row {
                    display: flex;
                    flex-direction: column;
                    row-gap: 0;

                    > :first-child,
                    > :last-child {
                        width: 100%;
                    }
                }
            }
        }
    }
}
</style>